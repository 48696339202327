import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux"
// import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Container from "@mui/material/Container";
import { spacing } from '@mui/system';

import * as REDUCER from "../reducers/shipmentReducer";
import {
  getCurrencies,
} from "../reducers/currenciesReducer";


const termsOfSaleTypes = [
  //   {value: 'FCA', label: 'FCA - Free Carrier'},
  //   {value: 'CPT', label: 'CPT - Carried Paid To'},
  //   {value: 'CIP', label: 'CIP - Carriage and Insurance Paid'},
  //   {value: 'DAT', label: 'DAT - Delivered At Terminal'},
    {value: 'DAP', 
  //   label: 'DDU - Charge the receiver for duties and taxes', 
      label: <span style={{fontSize: "0.7rem"}}>DDU - Charge the <strong style={{fontSize:"0.9rem", color: "red"}}>Receiver</strong> for the duties and taxes</span>},
    {value: 'DDP', 
  //   label: 'DDP - Charge sender for duties and taxes', 
      label: <span style={{fontSize: "0.7rem"}}>DDP - Charge the <strong style={{fontSize:"0.9rem", color: "red"}}>Sender</strong> for the duties and taxes</span>},
    {value: 'EXW', 
    //   label: 'DDU - Charge the receiver for duties and taxes', 
        label: <span style={{fontSize: "0.7rem"}}>EXW - Charge the <strong style={{fontSize:"0.9rem", color: "red"}}>Receiver</strong> for the duties and taxes</span>},
      //   {value: 'FAS', label: 'FAS'},
  //   {value: 'FOB', label: 'FOB'},
  //   {value: 'CFR', label: 'CFR'},
  //   {value: 'CIF', label: 'CIF'},
  ];
  
const contentTypes = [
      {value: 'DOCUMENTS', label: 'DOCUMENTS'},
      {value: 'GIFT', label: 'GIFT'},
      {value: 'SAMPLE', label: 'SAMPLE'},
      {value: 'MERCHANDISE', label: 'MERCHANDISE'},
      {value: 'HUMANITARIAN_DONATION', label: 'HUMANITARIAN_DONATION'},
      {value: 'RETURN_MERCHANDISE', label: 'RETURN_MERCHANDISE'},
      {value: 'OTHER', label: 'OTHER'}
  ];
    
const noDeliveryOptions = [
    {value: 'ABANDON', label: 'ABANDON'},
    {value: 'RETURN', label: 'RETURN'}
  ];
  
let currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: "GBP", 
    label: '£'
  },
  {
    value: "CHF", 
    label: 'CHF'
  },
  {
    value: "SEK", 
    label: 'kr'
  },
  {
    value: "PLN", 
    label: 'zł'
  },
  {
    value: "ILS", 
    label: '₪'
  },
  {
    value: "JPY", 
    label: '¥'
  },
];

export default ()=> {
  const classes = useTheme();
  const proposalState = useSelector(state => state.proposalDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrencies())
    }, []);

  let state_currencies = useSelector(state => state.currencies);
  if( state_currencies.currencies.length > 0) currencies = state_currencies.currencies
  const [currency, setCurrency] = useState(()=>{
    let retVal = [];
    switch(proposalState[0].source_country.short_format){
      case "DE":
      case "FR":
      case "IT":
      case "ES":
      case "DE":
      case "PT":
      case "BE":
      case "NL":
      case "AT":
      case "GR":
      case "FI":
      case "IE":
      case "CY":
      case "LU":
      case "HU":
        retVal = currencies.filter((cur=>{
        return cur.value === "EUR";
      }))
      break;
      case "CH":
        retVal = currencies.filter((cur=>{
        return cur.value === "CHF";
      }))
      break;
      case "SE":
        retVal = currencies.filter((cur=>{
        return cur.value === "SEK";
      }))
      break;
      case "UK":
      case "GB":
        retVal = currencies.filter((cur=>{
        return cur.value === "GBP";
      }))
      break;
      case "JP":
        retVal = currencies.filter((cur=>{
        return cur.value === "JPY";
      }))
      break;
      case "IL":
        retVal = currencies.filter((cur=>{
        return cur.value === "ILS";
      }))
      break;
      default:
        retVal = currencies.filter((cur=>{
          return cur.value === "USD";
        }))
      break;
    }
    if( !retVal.length) return "";
    dispatch(REDUCER.setCustomsCurrency(retVal[0].value))
    return retVal[0];
  });

  const updateCurrency = (val)=>{
    setCurrency(val)
    dispatch(REDUCER.setCustomsCurrency(val.value))
  }

  const isDocuments = proposalState[0].customs.content_type === "DOCUMENTS"

  return (
    <Container>
    <form noValidate autoComplete="off">
      <TextField
        required
        select 
        disabled={isDocuments}
        id="content"
        label="Content"
        className={classes.textField}
        value={proposalState[0].customs.content_type}
        onChange={(e)=>dispatch(REDUCER.setCustomsContentType(e.target.value))}
        sx={{m: 1}} 
        variant="standard" 
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
      >
        {contentTypes.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        required
        select 
        id="terms"
        label="Terms of Sale"
        className={classes.textField}
        value={proposalState[0].customs.terms_of_sale}
        onChange={(e)=>dispatch(REDUCER.setCustomsTermsOfSale(e.target.value))}
        sx={{m: 1}} 
        variant="standard" 
      >
      {termsOfSaleTypes.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      </TextField>
      <TextField
        required
        disabled={isDocuments}
        type="number" 
        id="value"
        label="Value"
        className={classes.textField}
        value={proposalState[0].customs.value}
        onChange={(e)=>{
          let value = +e.target.value;

          if (value < 1) value = 1;
          dispatch(REDUCER.setCustomsValue(value))}
        }
        variant="standard" 
        sx={{m: 1}} 
        InputProps={{
          inputProps: { 
              min: 1 
          }
        }}
      />
      <TextField
        select 
        disabled = {isDocuments} 
        id="currency"
        label=" "
        className={classes.currencyField}
        value={currency.value}
        onChange={(e)=>updateCurrency(e.target)}
        sx={{m: 1}} 
        variant="standard" 
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
      >
        {currencies.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        required
        id="description"
        label="Description"
        className={classes.textField}
        value={proposalState[0].customs.description}
        onChange={(e)=>dispatch(REDUCER.setCustomsDescription(e.target.value))}
        sx={{m: 1}} 
        variant="standard" 
      />
      <TextField
        required
        select 
        id="no_delivery"
        label="No Delivery"
        className={classes.textField}
        value={proposalState[0].customs.no_delivery}
        onChange={(e)=>dispatch(REDUCER.setCustomsNoDelivery(e.target.value))}
        sx={{m: 1}} 
        variant="standard" 
      >
        {noDeliveryOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {/* <TextField
        required
        id="signer"
        label="Signer's Name"
        className={classes.textField}
        value={proposalState[0].customs.signer}
        onChange={(e)=>dispatch(REDUCER.setCustomsSigner(e.target.value))}
        margin="normal"
      /> */}
    </form>
    </Container>
    )
}
